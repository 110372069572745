.country-option-flag-container {
  display: inline-block;
  width: 20px;
}
.country-option-flag-container {
  margin-right: 8px;
}
[dir='rtl'] .country-option-flag-container {
  margin-right: 0;
  margin-left: 8px;
}

.country-option-flag {
  width: 20px;
}
.country-option-flag.country-code__be {
  width: 18px;
}
.country-option-flag.country-code__ch {
  width: 15px;
}
.country-option-flag.country-code__mc {
  width: 19px;
}
.country-option-flag.country-code__ne {
  width: 18px;
}
.country-option-flag.country-code__np {
  width: 13px;
}
.country-option-flag.country-code__va {
  width: 15px;
}
@media (min-resolution: 2x) {
  .country-option-flag {
    background-size: 5762px 15px;
  }
}
.country-option-flag.country-code__ac {
  height: 10px;
  background-position: 0 0;
}
.country-option-flag.country-code__ad {
  height: 14px;
  background-position: -22px 0;
}
.country-option-flag.country-code__ae {
  height: 10px;
  background-position: -44px 0;
}
.country-option-flag.country-code__af {
  height: 14px;
  background-position: -66px 0;
}
.country-option-flag.country-code__ag {
  height: 14px;
  background-position: -88px 0;
}
.country-option-flag.country-code__ai {
  height: 10px;
  background-position: -110px 0;
}
.country-option-flag.country-code__al {
  height: 15px;
  background-position: -132px 0;
}
.country-option-flag.country-code__am {
  height: 10px;
  background-position: -154px 0;
}
.country-option-flag.country-code__ao {
  height: 14px;
  background-position: -176px 0;
}
.country-option-flag.country-code__aq {
  height: 14px;
  background-position: -198px 0;
}
.country-option-flag.country-code__ar {
  height: 13px;
  background-position: -220px 0;
}
.country-option-flag.country-code__as {
  height: 10px;
  background-position: -242px 0;
}
.country-option-flag.country-code__at {
  height: 14px;
  background-position: -264px 0;
}
.country-option-flag.country-code__au {
  height: 10px;
  background-position: -286px 0;
}
.country-option-flag.country-code__aw {
  height: 14px;
  background-position: -308px 0;
}
.country-option-flag.country-code__ax {
  height: 13px;
  background-position: -330px 0;
}
.country-option-flag.country-code__az {
  height: 10px;
  background-position: -352px 0;
}
.country-option-flag.country-code__ba {
  height: 10px;
  background-position: -374px 0;
}
.country-option-flag.country-code__bb {
  height: 14px;
  background-position: -396px 0;
}
.country-option-flag.country-code__bd {
  height: 12px;
  background-position: -418px 0;
}
.country-option-flag.country-code__be {
  height: 15px;
  background-position: -440px 0;
}
.country-option-flag.country-code__bf {
  height: 14px;
  background-position: -460px 0;
}
.country-option-flag.country-code__bg {
  height: 12px;
  background-position: -482px 0;
}
.country-option-flag.country-code__bh {
  height: 12px;
  background-position: -504px 0;
}
.country-option-flag.country-code__bi {
  height: 12px;
  background-position: -526px 0;
}
.country-option-flag.country-code__bj {
  height: 14px;
  background-position: -548px 0;
}
.country-option-flag.country-code__bl {
  height: 14px;
  background-position: -570px 0;
}
.country-option-flag.country-code__bm {
  height: 10px;
  background-position: -592px 0;
}
.country-option-flag.country-code__bn {
  height: 10px;
  background-position: -614px 0;
}
.country-option-flag.country-code__bo {
  height: 14px;
  background-position: -636px 0;
}
.country-option-flag.country-code__bq {
  height: 14px;
  background-position: -658px 0;
}
.country-option-flag.country-code__br {
  height: 14px;
  background-position: -680px 0;
}
.country-option-flag.country-code__bs {
  height: 10px;
  background-position: -702px 0;
}
.country-option-flag.country-code__bt {
  height: 14px;
  background-position: -724px 0;
}
.country-option-flag.country-code__bv {
  height: 15px;
  background-position: -746px 0;
}
.country-option-flag.country-code__bw {
  height: 14px;
  background-position: -768px 0;
}
.country-option-flag.country-code__by {
  height: 10px;
  background-position: -790px 0;
}
.country-option-flag.country-code__bz {
  height: 12px;
  background-position: -812px 0;
}
.country-option-flag.country-code__ca {
  height: 10px;
  background-position: -834px 0;
}
.country-option-flag.country-code__cc {
  height: 10px;
  background-position: -856px 0;
}
.country-option-flag.country-code__cd {
  height: 15px;
  background-position: -878px 0;
}
.country-option-flag.country-code__cf {
  height: 14px;
  background-position: -900px 0;
}
.country-option-flag.country-code__cg {
  height: 14px;
  background-position: -922px 0;
}
.country-option-flag.country-code__ch {
  height: 15px;
  background-position: -944px 0;
}
.country-option-flag.country-code__ci {
  height: 14px;
  background-position: -961px 0;
}
.country-option-flag.country-code__ck {
  height: 10px;
  background-position: -983px 0;
}
.country-option-flag.country-code__cl {
  height: 14px;
  background-position: -1005px 0;
}
.country-option-flag.country-code__cm {
  height: 14px;
  background-position: -1027px 0;
}
.country-option-flag.country-code__cn {
  height: 14px;
  background-position: -1049px 0;
}
.country-option-flag.country-code__co {
  height: 14px;
  background-position: -1071px 0;
}
.country-option-flag.country-code__cp {
  height: 14px;
  background-position: -1093px 0;
}
.country-option-flag.country-code__cq {
  height: 12px;
  background-position: -1115px 0;
}
.country-option-flag.country-code__cr {
  height: 12px;
  background-position: -1137px 0;
}
.country-option-flag.country-code__cu {
  height: 10px;
  background-position: -1159px 0;
}
.country-option-flag.country-code__cv {
  height: 12px;
  background-position: -1181px 0;
}
.country-option-flag.country-code__cw {
  height: 14px;
  background-position: -1203px 0;
}
.country-option-flag.country-code__cx {
  height: 10px;
  background-position: -1225px 0;
}
.country-option-flag.country-code__cy {
  height: 14px;
  background-position: -1247px 0;
}
.country-option-flag.country-code__cz {
  height: 14px;
  background-position: -1269px 0;
}
.country-option-flag.country-code__de {
  height: 12px;
  background-position: -1291px 0;
}
.country-option-flag.country-code__dg {
  height: 10px;
  background-position: -1313px 0;
}
.country-option-flag.country-code__dj {
  height: 14px;
  background-position: -1335px 0;
}
.country-option-flag.country-code__dk {
  height: 15px;
  background-position: -1357px 0;
}
.country-option-flag.country-code__dm {
  height: 10px;
  background-position: -1379px 0;
}
.country-option-flag.country-code__do {
  height: 14px;
  background-position: -1401px 0;
}
.country-option-flag.country-code__dz {
  height: 14px;
  background-position: -1423px 0;
}
.country-option-flag.country-code__ea {
  height: 14px;
  background-position: -1445px 0;
}
.country-option-flag.country-code__ec {
  height: 14px;
  background-position: -1467px 0;
}
.country-option-flag.country-code__ee {
  height: 13px;
  background-position: -1489px 0;
}
.country-option-flag.country-code__eg {
  height: 14px;
  background-position: -1511px 0;
}
.country-option-flag.country-code__eh {
  height: 10px;
  background-position: -1533px 0;
}
.country-option-flag.country-code__er {
  height: 10px;
  background-position: -1555px 0;
}
.country-option-flag.country-code__es {
  height: 14px;
  background-position: -1577px 0;
}
.country-option-flag.country-code__et {
  height: 10px;
  background-position: -1599px 0;
}
.country-option-flag.country-code__eu {
  height: 14px;
  background-position: -1621px 0;
}
.country-option-flag.country-code__ez {
  height: 14px;
  background-position: -1643px 0;
}
.country-option-flag.country-code__fi {
  height: 12px;
  background-position: -1665px 0;
}
.country-option-flag.country-code__fj {
  height: 10px;
  background-position: -1687px 0;
}
.country-option-flag.country-code__fk {
  height: 10px;
  background-position: -1709px 0;
}
.country-option-flag.country-code__fm {
  height: 11px;
  background-position: -1731px 0;
}
.country-option-flag.country-code__fo {
  height: 15px;
  background-position: -1753px 0;
}
.country-option-flag.country-code__fr {
  height: 14px;
  background-position: -1775px 0;
}
.country-option-flag.country-code__fx {
  height: 14px;
  background-position: -1797px 0;
}
.country-option-flag.country-code__ga {
  height: 15px;
  background-position: -1819px 0;
}
.country-option-flag.country-code__gb {
  height: 10px;
  background-position: -1841px 0;
}
.country-option-flag.country-code__gd {
  height: 12px;
  background-position: -1863px 0;
}
.country-option-flag.country-code__ge {
  height: 14px;
  background-position: -1885px 0;
}
.country-option-flag.country-code__gf {
  height: 14px;
  background-position: -1907px 0;
}
.country-option-flag.country-code__gg {
  height: 14px;
  background-position: -1929px 0;
}
.country-option-flag.country-code__gh {
  height: 14px;
  background-position: -1951px 0;
}
.country-option-flag.country-code__gi {
  height: 10px;
  background-position: -1973px 0;
}
.country-option-flag.country-code__gl {
  height: 14px;
  background-position: -1995px 0;
}
.country-option-flag.country-code__gm {
  height: 14px;
  background-position: -2017px 0;
}
.country-option-flag.country-code__gn {
  height: 14px;
  background-position: -2039px 0;
}
.country-option-flag.country-code__gp {
  height: 14px;
  background-position: -2061px 0;
}
.country-option-flag.country-code__gq {
  height: 14px;
  background-position: -2083px 0;
}
.country-option-flag.country-code__gr {
  height: 14px;
  background-position: -2105px 0;
}
.country-option-flag.country-code__gs {
  height: 10px;
  background-position: -2127px 0;
}
.country-option-flag.country-code__gt {
  height: 13px;
  background-position: -2149px 0;
}
.country-option-flag.country-code__gu {
  height: 11px;
  background-position: -2171px 0;
}
.country-option-flag.country-code__gw {
  height: 10px;
  background-position: -2193px 0;
}
.country-option-flag.country-code__gy {
  height: 12px;
  background-position: -2215px 0;
}
.country-option-flag.country-code__hk {
  height: 14px;
  background-position: -2237px 0;
}
.country-option-flag.country-code__hm {
  height: 10px;
  background-position: -2259px 0;
}
.country-option-flag.country-code__hn {
  height: 10px;
  background-position: -2281px 0;
}
.country-option-flag.country-code__hr {
  height: 10px;
  background-position: -2303px 0;
}
.country-option-flag.country-code__ht {
  height: 12px;
  background-position: -2325px 0;
}
.country-option-flag.country-code__hu {
  height: 10px;
  background-position: -2347px 0;
}
.country-option-flag.country-code__ic {
  height: 14px;
  background-position: -2369px 0;
}
.country-option-flag.country-code__id {
  height: 14px;
  background-position: -2391px 0;
}
.country-option-flag.country-code__ie {
  height: 10px;
  background-position: -2413px 0;
}
.country-option-flag.country-code__il {
  height: 15px;
  background-position: -2435px 0;
}
.country-option-flag.country-code__im {
  height: 10px;
  background-position: -2457px 0;
}
.country-option-flag.country-code__in {
  height: 14px;
  background-position: -2479px 0;
}
.country-option-flag.country-code__io {
  height: 10px;
  background-position: -2501px 0;
}
.country-option-flag.country-code__iq {
  height: 14px;
  background-position: -2523px 0;
}
.country-option-flag.country-code__ir {
  height: 12px;
  background-position: -2545px 0;
}
.country-option-flag.country-code__is {
  height: 15px;
  background-position: -2567px 0;
}
.country-option-flag.country-code__it {
  height: 14px;
  background-position: -2589px 0;
}
.country-option-flag.country-code__je {
  height: 12px;
  background-position: -2611px 0;
}
.country-option-flag.country-code__jm {
  height: 10px;
  background-position: -2633px 0;
}
.country-option-flag.country-code__jo {
  height: 10px;
  background-position: -2655px 0;
}
.country-option-flag.country-code__jp {
  height: 14px;
  background-position: -2677px 0;
}
.country-option-flag.country-code__ke {
  height: 14px;
  background-position: -2699px 0;
}
.country-option-flag.country-code__kg {
  height: 12px;
  background-position: -2721px 0;
}
.country-option-flag.country-code__kh {
  height: 13px;
  background-position: -2743px 0;
}
.country-option-flag.country-code__ki {
  height: 10px;
  background-position: -2765px 0;
}
.country-option-flag.country-code__km {
  height: 12px;
  background-position: -2787px 0;
}
.country-option-flag.country-code__kn {
  height: 14px;
  background-position: -2809px 0;
}
.country-option-flag.country-code__kp {
  height: 10px;
  background-position: -2831px 0;
}
.country-option-flag.country-code__kr {
  height: 14px;
  background-position: -2853px 0;
}
.country-option-flag.country-code__kw {
  height: 10px;
  background-position: -2875px 0;
}
.country-option-flag.country-code__ky {
  height: 10px;
  background-position: -2897px 0;
}
.country-option-flag.country-code__kz {
  height: 10px;
  background-position: -2919px 0;
}
.country-option-flag.country-code__la {
  height: 14px;
  background-position: -2941px 0;
}
.country-option-flag.country-code__lb {
  height: 14px;
  background-position: -2963px 0;
}
.country-option-flag.country-code__lc {
  height: 10px;
  background-position: -2985px 0;
}
.country-option-flag.country-code__li {
  height: 12px;
  background-position: -3007px 0;
}
.country-option-flag.country-code__lk {
  height: 10px;
  background-position: -3029px 0;
}
.country-option-flag.country-code__lr {
  height: 11px;
  background-position: -3051px 0;
}
.country-option-flag.country-code__ls {
  height: 14px;
  background-position: -3073px 0;
}
.country-option-flag.country-code__lt {
  height: 12px;
  background-position: -3095px 0;
}
.country-option-flag.country-code__lu {
  height: 12px;
  background-position: -3117px 0;
}
.country-option-flag.country-code__lv {
  height: 10px;
  background-position: -3139px 0;
}
.country-option-flag.country-code__ly {
  height: 10px;
  background-position: -3161px 0;
}
.country-option-flag.country-code__ma {
  height: 14px;
  background-position: -3183px 0;
}
.country-option-flag.country-code__mc {
  height: 15px;
  background-position: -3205px 0;
}
.country-option-flag.country-code__md {
  height: 10px;
  background-position: -3226px 0;
}
.country-option-flag.country-code__me {
  height: 10px;
  background-position: -3248px 0;
}
.country-option-flag.country-code__mf {
  height: 14px;
  background-position: -3270px 0;
}
.country-option-flag.country-code__mg {
  height: 14px;
  background-position: -3292px 0;
}
.country-option-flag.country-code__mh {
  height: 11px;
  background-position: -3314px 0;
}
.country-option-flag.country-code__mk {
  height: 10px;
  background-position: -3336px 0;
}
.country-option-flag.country-code__ml {
  height: 14px;
  background-position: -3358px 0;
}
.country-option-flag.country-code__mm {
  height: 14px;
  background-position: -3380px 0;
}
.country-option-flag.country-code__mn {
  height: 10px;
  background-position: -3402px 0;
}
.country-option-flag.country-code__mo {
  height: 14px;
  background-position: -3424px 0;
}
.country-option-flag.country-code__mp {
  height: 10px;
  background-position: -3446px 0;
}
.country-option-flag.country-code__mq {
  height: 14px;
  background-position: -3468px 0;
}
.country-option-flag.country-code__mr {
  height: 14px;
  background-position: -3490px 0;
}
.country-option-flag.country-code__ms {
  height: 10px;
  background-position: -3512px 0;
}
.country-option-flag.country-code__mt {
  height: 14px;
  background-position: -3534px 0;
}
.country-option-flag.country-code__mu {
  height: 14px;
  background-position: -3556px 0;
}
.country-option-flag.country-code__mv {
  height: 14px;
  background-position: -3578px 0;
}
.country-option-flag.country-code__mw {
  height: 14px;
  background-position: -3600px 0;
}
.country-option-flag.country-code__mx {
  height: 12px;
  background-position: -3622px 0;
}
.country-option-flag.country-code__my {
  height: 10px;
  background-position: -3644px 0;
}
.country-option-flag.country-code__mz {
  height: 14px;
  background-position: -3666px 0;
}
.country-option-flag.country-code__na {
  height: 14px;
  background-position: -3688px 0;
}
.country-option-flag.country-code__nc {
  height: 10px;
  background-position: -3710px 0;
}
.country-option-flag.country-code__ne {
  height: 15px;
  background-position: -3732px 0;
}
.country-option-flag.country-code__nf {
  height: 10px;
  background-position: -3752px 0;
}
.country-option-flag.country-code__ng {
  height: 10px;
  background-position: -3774px 0;
}
.country-option-flag.country-code__ni {
  height: 12px;
  background-position: -3796px 0;
}
.country-option-flag.country-code__nl {
  height: 14px;
  background-position: -3818px 0;
}
.country-option-flag.country-code__no {
  height: 15px;
  background-position: -3840px 0;
}
.country-option-flag.country-code__np {
  height: 15px;
  background-position: -3862px 0;
}
.country-option-flag.country-code__nr {
  height: 10px;
  background-position: -3877px 0;
}
.country-option-flag.country-code__nu {
  height: 10px;
  background-position: -3899px 0;
}
.country-option-flag.country-code__nz {
  height: 10px;
  background-position: -3921px 0;
}
.country-option-flag.country-code__om {
  height: 10px;
  background-position: -3943px 0;
}
.country-option-flag.country-code__pa {
  height: 14px;
  background-position: -3965px 0;
}
.country-option-flag.country-code__pe {
  height: 14px;
  background-position: -3987px 0;
}
.country-option-flag.country-code__pf {
  height: 14px;
  background-position: -4009px 0;
}
.country-option-flag.country-code__pg {
  height: 15px;
  background-position: -4031px 0;
}
.country-option-flag.country-code__ph {
  height: 10px;
  background-position: -4053px 0;
}
.country-option-flag.country-code__pk {
  height: 14px;
  background-position: -4075px 0;
}
.country-option-flag.country-code__pl {
  height: 13px;
  background-position: -4097px 0;
}
.country-option-flag.country-code__pm {
  height: 14px;
  background-position: -4119px 0;
}
.country-option-flag.country-code__pn {
  height: 10px;
  background-position: -4141px 0;
}
.country-option-flag.country-code__pr {
  height: 14px;
  background-position: -4163px 0;
}
.country-option-flag.country-code__ps {
  height: 10px;
  background-position: -4185px 0;
}
.country-option-flag.country-code__pt {
  height: 14px;
  background-position: -4207px 0;
}
.country-option-flag.country-code__pw {
  height: 13px;
  background-position: -4229px 0;
}
.country-option-flag.country-code__py {
  height: 11px;
  background-position: -4251px 0;
}
.country-option-flag.country-code__qa {
  height: 8px;
  background-position: -4273px 0;
}
.country-option-flag.country-code__re {
  height: 14px;
  background-position: -4295px 0;
}
.country-option-flag.country-code__ro {
  height: 14px;
  background-position: -4317px 0;
}
.country-option-flag.country-code__rs {
  height: 14px;
  background-position: -4339px 0;
}
.country-option-flag.country-code__ru {
  height: 14px;
  background-position: -4361px 0;
}
.country-option-flag.country-code__rw {
  height: 14px;
  background-position: -4383px 0;
}
.country-option-flag.country-code__sa {
  height: 14px;
  background-position: -4405px 0;
}
.country-option-flag.country-code__sb {
  height: 10px;
  background-position: -4427px 0;
}
.country-option-flag.country-code__sc {
  height: 10px;
  background-position: -4449px 0;
}
.country-option-flag.country-code__sd {
  height: 10px;
  background-position: -4471px 0;
}
.country-option-flag.country-code__se {
  height: 13px;
  background-position: -4493px 0;
}
.country-option-flag.country-code__sg {
  height: 14px;
  background-position: -4515px 0;
}
.country-option-flag.country-code__sh {
  height: 10px;
  background-position: -4537px 0;
}
.country-option-flag.country-code__si {
  height: 10px;
  background-position: -4559px 0;
}
.country-option-flag.country-code__sj {
  height: 15px;
  background-position: -4581px 0;
}
.country-option-flag.country-code__sk {
  height: 14px;
  background-position: -4603px 0;
}
.country-option-flag.country-code__sl {
  height: 14px;
  background-position: -4625px 0;
}
.country-option-flag.country-code__sm {
  height: 15px;
  background-position: -4647px 0;
}
.country-option-flag.country-code__sn {
  height: 14px;
  background-position: -4669px 0;
}
.country-option-flag.country-code__so {
  height: 14px;
  background-position: -4691px 0;
}
.country-option-flag.country-code__sr {
  height: 14px;
  background-position: -4713px 0;
}
.country-option-flag.country-code__ss {
  height: 10px;
  background-position: -4735px 0;
}
.country-option-flag.country-code__st {
  height: 10px;
  background-position: -4757px 0;
}
.country-option-flag.country-code__su {
  height: 10px;
  background-position: -4779px 0;
}
.country-option-flag.country-code__sv {
  height: 12px;
  background-position: -4801px 0;
}
.country-option-flag.country-code__sx {
  height: 14px;
  background-position: -4823px 0;
}
.country-option-flag.country-code__sy {
  height: 14px;
  background-position: -4845px 0;
}
.country-option-flag.country-code__sz {
  height: 14px;
  background-position: -4867px 0;
}
.country-option-flag.country-code__ta {
  height: 10px;
  background-position: -4889px 0;
}
.country-option-flag.country-code__tc {
  height: 10px;
  background-position: -4911px 0;
}
.country-option-flag.country-code__td {
  height: 14px;
  background-position: -4933px 0;
}
.country-option-flag.country-code__tf {
  height: 14px;
  background-position: -4955px 0;
}
.country-option-flag.country-code__tg {
  height: 13px;
  background-position: -4977px 0;
}
.country-option-flag.country-code__th {
  height: 14px;
  background-position: -4999px 0;
}
.country-option-flag.country-code__tj {
  height: 10px;
  background-position: -5021px 0;
}
.country-option-flag.country-code__tk {
  height: 10px;
  background-position: -5043px 0;
}
.country-option-flag.country-code__tl {
  height: 10px;
  background-position: -5065px 0;
}
.country-option-flag.country-code__tm {
  height: 14px;
  background-position: -5087px 0;
}
.country-option-flag.country-code__tn {
  height: 14px;
  background-position: -5109px 0;
}
.country-option-flag.country-code__to {
  height: 10px;
  background-position: -5131px 0;
}
.country-option-flag.country-code__tr {
  height: 14px;
  background-position: -5153px 0;
}
.country-option-flag.country-code__tt {
  height: 12px;
  background-position: -5175px 0;
}
.country-option-flag.country-code__tv {
  height: 10px;
  background-position: -5197px 0;
}
.country-option-flag.country-code__tw {
  height: 14px;
  background-position: -5219px 0;
}
.country-option-flag.country-code__tz {
  height: 14px;
  background-position: -5241px 0;
}
.country-option-flag.country-code__ua {
  height: 14px;
  background-position: -5263px 0;
}
.country-option-flag.country-code__ug {
  height: 14px;
  background-position: -5285px 0;
}
.country-option-flag.country-code__uk {
  height: 10px;
  background-position: -5307px 0;
}
.country-option-flag.country-code__um {
  height: 11px;
  background-position: -5329px 0;
}
.country-option-flag.country-code__un {
  height: 14px;
  background-position: -5351px 0;
}
.country-option-flag.country-code__us {
  height: 11px;
  background-position: -5373px 0;
}
.country-option-flag.country-code__uy {
  height: 14px;
  background-position: -5395px 0;
}
.country-option-flag.country-code__uz {
  height: 10px;
  background-position: -5417px 0;
}
.country-option-flag.country-code__va {
  height: 15px;
  background-position: -5439px 0;
}
.country-option-flag.country-code__vc {
  height: 14px;
  background-position: -5456px 0;
}
.country-option-flag.country-code__ve {
  height: 14px;
  background-position: -5478px 0;
}
.country-option-flag.country-code__vg {
  height: 10px;
  background-position: -5500px 0;
}
.country-option-flag.country-code__vi {
  height: 14px;
  background-position: -5522px 0;
}
.country-option-flag.country-code__vn {
  height: 14px;
  background-position: -5544px 0;
}
.country-option-flag.country-code__vu {
  height: 12px;
  background-position: -5566px 0;
}
.country-option-flag.country-code__wf {
  height: 14px;
  background-position: -5588px 0;
}
.country-option-flag.country-code__ws {
  height: 10px;
  background-position: -5610px 0;
}
.country-option-flag.country-code__xk {
  height: 15px;
  background-position: -5632px 0;
}
.country-option-flag.country-code__ye {
  height: 14px;
  background-position: -5654px 0;
}
.country-option-flag.country-code__yt {
  height: 14px;
  background-position: -5676px 0;
}
.country-option-flag.country-code__za {
  height: 14px;
  background-position: -5698px 0;
}
.country-option-flag.country-code__zm {
  height: 14px;
  background-position: -5720px 0;
}
.country-option-flag.country-code__zw {
  height: 10px;
  background-position: -5742px 0;
}
.country-option-flag {
  height: 15px;
  box-shadow: 0 0 1px 0 #888;
  background-image: url('../img/flags-full.png?1');
  background-repeat: no-repeat;
  background-position: 20px 0;
  border-radius: 2px;
}
@media (min-resolution: 2x) {
  .country-option-flag {
    background-image: url('../img/flags-full@2x.png?1');
  }
}
