// ******* BREAKPOINTS *******
/**
* It is to be noted that the minimum screen width that we take into account for
* responsive design is 320px
**/

/* Extra extra small devices (phones vertical screen) */
$xxs-max-width: 479px;

/* Extra small devices (phones, less than 768px) */
$xs-min-width: 480px;
$xs-max-width: 767px;

/* Small devices (tablets, 768px and up) */
$sm-min-width: 768px;
$sm-max-width: 991px;

/* Medium devices (desktops, 992px and up) */
$md-min-width: 992px;
$md-max-width: 1199px;

/* Large devices (large desktops, 1200px and up) */
$lg-min-width: 1200px;
