@use '@angular/material' as mat;
@import './variables';
@import './mixins';
@import 'intlTelInput';
@import 'bootstrap/scss/bootstrap';

@include mat.core();

$my-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$violet-palette,
  )
));

@import url('https://cdn.doctena.com/angular-material/font-material-icon.css');
@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.doctena.com/css/fonts/jost/jost-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://cdn.doctena.com/css/fonts/jost/jost-semi-bold.woff2") format("woff2");
}

@font-face {
  font-family: "Jost";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("https://cdn.doctena.com/css/fonts/jost/jost-extra-bold.woff2") format("woff2");
}

*:not(mat-icon) {
  font-family: 'Jost', sans-serif !important;
}

.material-icons, .reset-btn {
  font-family: 'Material Icons' !important;
}

/** powered by Doctena **/
.powered-by {
  color: #b0b0b0;
  font-size: 14px;
  margin: 0 10px 10px 0;
  float: right;
}

.powered-by a {
  color: #7eb8cb;

}

.powered-by p {
  display: inline-block;
}

.alert-doctena {
  border-color: #8F9EB7;
  color: #304D79;
}

a.link:not([href]) {
  color: #337ab7;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.btn-success {
  background-color: #4dab36;
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    background: darken(#4dab36, 10%);
    color: #fff;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2em lighten(#4dab36, 15%);
  }
}

.btn-secondary {
  background-color: #cacaca !important;
  color: #fff !important;
}

/* margins */

.mg-top {
  margin-top: 20px;
}

.semi-mg-top {
  margin-top: 10px;
}

.no-mg-top {
  margin-top: 0;
}

.mg-bottom {
  margin-bottom: 20px;
}

.semi-mg-bottom {
  margin-bottom: 10px;
}

.no-mg-bottom {
  margin-bottom: 0;
}

.mg-left {
  margin-left: 20px;
}

.semi-mg-left {
  margin-left: 10px;
}

.no-mg-left {
  margin-left: 0;
}

.mg-right {
  margin-right: 20px;
}

.semi-mg-right {
  margin-right: 10px;
}

.no-mg-right {
  margin-right: 0;
}

/* container classes */
.valign {
  display: flex;
  align-items: center;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.newlines {
  white-space: pre-line;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

div mat-error.mat-mdc-form-field-error {
  padding-left: 16px !important;
  font-size: 12px !important;
  letter-spacing: var(--mat-form-field-container-text-tracking);
  font-weight: normal;
}

.mat-mdc-checkbox label, .mat-mdc-radio-button label {
  font-family: var(--mat-form-field-container-text-font);
  font-size: var(--bs-body-font-size);
}
